
    import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
    import {computed, defineComponent, ref, toRefs, watch } from "vue"
    import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
    import { getModule } from 'vuex-module-decorators'
    import {  Form, Field } from "vee-validate";
    import * as Validations from "yup";
    import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
    import SaleTypeModule from "@/store/modules/saleType/modules/new-saletype";
    import { useRouter } from "vue-router";
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    import { translate } from "@/core/plugins/i18n";
    import { SalesTypes } from "@/core/enums/sales-types";
    import SaleReturnModule from "@/store/modules/salesreturn/modules/table";
    import NewSaleReturnModule from "@/store/modules/salesreturn/modules/new-salereturn"
    import InfoSaleReturn from '@/models/salesreturn/infoSaleReturn'
    import FilterOptionSale from '@/models/salesreturn/filters/FilterOptionSale'

    export default defineComponent({
        components:{
            DynamicContentModal,
            Form,
            Field,
            SelectFilterRemote
        },
        props: {
           isActionAdd: {
               type: Number,
               default: SalesTypes.CreateSale
           }
        },
        emits: ['open-modal'],
        setup(props, {emit}) {
            //Propiedades
            const moduleSalesType = getModule(SaleTypeModule);
            const moduleSaleReturn = getModule(SaleReturnModule);
            const moduleNewSaleReturn = getModule(NewSaleReturnModule);

            const formReturnSaleInital = ref();
            const formSave = ref(false);
            const router = useRouter();
            const { isActionAdd } = toRefs(props);
            
            //Schema
            const validationSchema = Validations.object().shape({
              saleId: Validations.string().required().label("saleId"),
            });
                        
            //METODOS
            const handleSearchClient = (name: string) => {   
              if(name != ""){
                moduleNewSaleReturn.SEARCH_CLIENTS(name);  
              }                        
            }
            
            const handlerChangeClient = (id: string) => {              
              moduleNewSaleReturn.SAVE_CLIENT_SELECTED(id);
            }
    
            const handleSearchFolioSale = (id: string) => {
              if(id != "")
              {
                const idClientSelected = moduleNewSaleReturn.getClientSelected;
                const filterOptionSale = {
                  id: id
                  ,idClientSelected: idClientSelected ? idClientSelected : ""
                } as FilterOptionSale;
                moduleNewSaleReturn.SEARCH_OPTIONSALE(filterOptionSale);
              } 
            }
    
            const submitForm = (data: any) => {            
                const saleReturnDataInital = {
                    customerId: data.customerId,
                    saleId: data.saleId,
                } as InfoSaleReturn;
    
                moduleNewSaleReturn.SEARCH_SALEDETAILS(data.saleId).then((a) => {
                  if(data.salereturnall)
                  {
                    moduleNewSaleReturn.SALERETURN_SELECTEDALL(saleReturnDataInital); 
                  }
                });                

                formSave.value = true;    
                hideModalById("#ModalNewReturnSaleForm");
            };
   
            const clientsCombo = computed(() => moduleNewSaleReturn.getClientOptions);
            const loadingClientCombo = computed(() => moduleNewSaleReturn.loadingClients);
            const loadingSaleTypeCombo = computed(() => moduleSalesType.loading);
            const saleTypes = computed(() => moduleSalesType.getSaleTypeOptions);
            const FolioSaleCombo = computed(() => moduleNewSaleReturn.getSaleOptions);
            const loadingFolioSale = computed(() => moduleSaleReturn.loading);
    
            const resetForm = () => {
                formReturnSaleInital.value.resetForm();
    
                if(!formSave.value){
                  Swal.fire({
                    title: `Es necesario ingresar los datos generales de la devolución, ¿Esta seguro de abandonar la devolución?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, salir',
                    cancelButtonText: "No"
                    }).then((result) => {
                      if (result.isConfirmed) {
                          setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALESRETURNS'), [translate('BREADCRUMB.MODULES')]);
                          router.back(); 
                      }else{
                        console.log("Esto es una prueba")
                        //emit("open-modal");
                      }
                  });
                }
            }
    
            return {
                validationSchema
                ,clientsCombo
                ,saleTypes
                ,formReturnSaleInital
                ,loadingClientCombo
                ,loadingSaleTypeCombo
                ,FolioSaleCombo
                ,loadingFolioSale
                ,SalesTypes
    
                ,submitForm
                ,handleSearchClient
                ,handlerChangeClient
                ,handleSearchFolioSale
                // ,handleSearchSaleType
                ,resetForm
            }
    
        },
        methods:{
            closeListener(){
                this.resetForm();
            },
            triggerHidden: function(){
                listenerHideModal("#ModalNewReturnSaleForm", this.closeListener);
            },
        },
        mounted(){
            this.triggerHidden();
        }
    })
    