
import { computed, defineComponent, reactive, ref, watch } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import {  Field, Form } from "vee-validate";
import { getModule } from "vuex-module-decorators";
import * as Validations from "yup";
import NewSaleReturnModule from "@/store/modules/salesreturn/modules/new-salereturn"
import SaleReturnDetails from '@/models/salesreturn/saleReturnDetails'
import { ReturnSalesTypes } from "@/core/enums/returnsales-type"

export default defineComponent({
    components:{
       ModuleMaster
        , Datatable
        , Datarow
        , Form
        , SelectFilterRemote
        , Field
    },
    props: {
       isActionAdd: {
           type: Number,
           default: ReturnSalesTypes.CreateSaleReturn
       }
    },
    setup(props, context){
        //Propiedades
        const moduleSaleDetails = getModule(NewSaleReturnModule);

        const openModalProductForm = ref(HTMLButtonElement);
        const showSearchProduct = ref(false);
        const formAddSaleReturn = ref();

        const quantity = ref();
        const price = ref("");
        const showAdd = ref(false);
                            
        const showExtras = ref(false);
        const showMoreInfo = ref(false);

        //Schema
        const validationSchema = Validations.object().shape({
            productId: Validations.string().required().default(null).label("productId"),
        });

        //METODOS
        const handlerChangeProduct = (id:string) => {
            moduleSaleDetails.SAVE_PRODUCT_SELECTED(id);
            const productSelected = moduleSaleDetails.getSaleDetailsAll.find(x => x.productId === id) as SaleReturnDetails;
            quantity.value = productSelected.quantity;
            price.value = "$ " + productSelected.price;
        }

        const productDetailsProductCombo = computed(() => moduleSaleDetails.getDetailsProducts);
        const loadingProductsCombo = computed(() => moduleSaleDetails.loading);
        
        const submitForm = (product: any) =>{
            const idProduct = moduleSaleDetails.idProductSelected;
            const discontSale = moduleSaleDetails.getDiscontSale;
            const productSelected = moduleSaleDetails.getSaleDetailsAll.find(x => x.productId === idProduct) as SaleReturnDetails;

            let subtotal = 0, totalDiscount = 0, totalTax = 0, total_discount = 0
                
            subtotal = product.quantity * productSelected.price;
            totalDiscount = productSelected.discountPersent > 0 ? 
                                           ((subtotal * productSelected.discountPersent) / 100) : 0;
            total_discount = subtotal - totalDiscount;
            totalTax = productSelected.taxPersent > 0 ? 
                                            ((total_discount * productSelected.taxPersent) / 100) : 0;
        
            const SaleDetail = {    
                id:'',         
                saleReturnId: productSelected ? productSelected.saleReturnId : '', 
                productId: productSelected ? productSelected.productId : '',
                saleId: productSelected ? productSelected.saleId : '',
                productName: productSelected ? productSelected.productName : '',
                price: productSelected ? productSelected.price : 0,
                tax: productSelected ? /*productSelected.tax*/totalTax  : 0,
                discountPersent: productSelected ? productSelected.discountPersent : 0,
                taxPersent: productSelected ? productSelected.taxPersent : 0,
                amount: product.quantity,
                discount: productSelected ? /*productSelected.discount*/ totalDiscount : 0,
                subTotal: subtotal,//(product.quantity * productSelected.price),
                total: productSelected ? /*productSelected.total*/total_discount + totalTax : 0,
                quantity: productSelected ? productSelected.quantity : 0,
                quantity_Ori: productSelected ? productSelected.amount : 0,
                total_Ori: productSelected ? productSelected.total : 0,
                unitOfMeasurementId: productSelected ? productSelected.unitOfMeasurementId : '',
                status: true
            } as SaleReturnDetails;

            moduleSaleDetails.SAVE_SALERETURN_SELECTED(SaleDetail)
         }        

         const handlerupdquant = (evt: number) => {

            showAdd.value = true;
            const idProduct = moduleSaleDetails.idProductSelected;
            const productSelected = moduleSaleDetails.getSelectedSaleDetailAdd as SaleReturnDetails;
            const discontSale = moduleSaleDetails.getDiscontSale;
            let IsExistGrid = moduleSaleDetails.getExistSaleDetailAdd;
            if(!IsExistGrid){                          
            //if(productSelected.quantity_Ori == undefined){
                productSelected.quantity_Ori = productSelected.amount ;
                //IsExistGrid = false;
            }else{
                showAdd.value = false;  
            }
                

            if(evt <= productSelected.quantity && evt > 0){ //quantity_Ori
                let subtotal = 0, totalDiscount = 0, totalTax = 0, total_discount = 0;
                
                subtotal = evt * productSelected.price;
                totalDiscount = productSelected.discountPersent > 0 ? 
                                           ((subtotal * productSelected.discountPersent) / 100) : 0;
                total_discount = subtotal - totalDiscount;
                totalTax = productSelected.taxPersent > 0 ? 
                                            ((total_discount * productSelected.taxPersent) / 100) : 0;

                productSelected.amount = evt;
                productSelected.subTotal = subtotal;
                productSelected.discount = totalDiscount;
                productSelected.tax = totalTax
                productSelected.total = total_discount + totalTax
                if(IsExistGrid){
                    context.emit("changedetailproduct",  productSelected.total)
                    moduleSaleDetails.UPD_SALERETURN_PRODUCTGRID(productSelected);
                }                
            }else{
                formAddSaleReturn.value.setValues({quantity: ''});
                showAdd.value = false;
            }
         }

         /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "productId", HeadLabel: "productId", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Descripción", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "moneda", VisibleInGrid: true },            
            { PropName: "subTotal", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },            
            { PropName: "discount", HeadLabel: "Descuento", Type: "moneda", VisibleInGrid: true }, 
            { PropName: "tax", HeadLabel: "Impuesto", Type: "moneda", VisibleInGrid: true },           
            { PropName: "total", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },                        
        ]);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Boton prueba",
        });
        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });


        const renderRows = computed(() => {
            return formatToDatatable(moduleSaleDetails.getTableSaleReturnDetailsAdd//getSaleReturnDetailsAdd
            , configTable, "", "productId");
        });

        const handleDeleteProduct = (id: string) => {
            moduleSaleDetails.DEL_SALERETURN_PRODUCTGRID(id);
        }
        
        return {
            headers
            , showMoreInfo
            , renderRows
            , labels
            , showSearchProduct
            , showExtras
            , openModalProductForm
            , ReturnSalesTypes
            , validationSchema
            , formAddSaleReturn
            , submitForm
            , productDetailsProductCombo
            , loadingProductsCombo
            , handlerChangeProduct
            , handlerupdquant
            , quantity
            , price
            , handleDeleteProduct
            , showAdd
         }
    }
})
