
import { ReturnSalesTypes } from "@/core/enums/returnsales-type";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { defineComponent, onMounted, ref } from "vue"
import ReturnSaleInformation from "./ReturnSalesInformation.vue"
import Table from "./TableReturnSalesInformation.vue"
import NewReturnSalesInitial from "./NewReturnSalesInitial.vue"

export default defineComponent({
    props:{
        action: {
            type: String
            , required: true
        }
    },
    components:{
        ReturnSaleInformation
        ,Table
        ,NewReturnSalesInitial
    },
    setup(props){
        const actionAdd = ref(ReturnSalesTypes.CreateSaleReturn);
        const openModalNewReturnSaleForm = ref(HTMLButtonElement);
        //EDITSALESRETURN, READSALESRETURN
        setCurrentPageBreadcrumbs((props.action == "new" ? translate('BREADCRUMB.NEWSALESRETURN')
                                                    : (props.action == "edit" ? translate('BREADCRUMB.EDITSALESRETURN') 
                                                    :  translate('BREADCRUMB.READSALESRETURN'))), [translate('BREADCRUMB.MODULES'), { name: translate('BREADCRUMB.SALESRETURNS'), to: "salesReturns" }]);
        
        const handlerOpenModal = () => {
            if(openModalNewReturnSaleForm.value){
                (openModalNewReturnSaleForm.value as unknown as HTMLButtonElement).click();
            }
        }

        onMounted(() => {
            let action: ReturnSalesTypes | undefined = props.action == "new" ? ReturnSalesTypes.CreateSaleReturn
                                                    : (props.action == "edit" ? ReturnSalesTypes.UpdateSaleReturn 
                                                    : props.action == "view" ? ReturnSalesTypes.ReadSaleReturn :
                                                                               ReturnSalesTypes.CreateSaleReturn );
            actionAdd.value = action;

            if(openModalNewReturnSaleForm.value && action == ReturnSalesTypes.CreateSaleReturn){
                (openModalNewReturnSaleForm.value as unknown as HTMLButtonElement).click();
            }
        });

       return {
           actionAdd 
           ,openModalNewReturnSaleForm
           ,handlerOpenModal
       }
    }
})
