import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = {
  hidden: "",
  type: "button",
  ref: "openModalNewReturnSaleForm",
  class: "btn btn-primary btn-active-light-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#ModalNewReturnSaleForm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!
  const _component_ReturnSaleInformation = _resolveComponent("ReturnSaleInformation")!
  const _component_NewReturnSalesInitial = _resolveComponent("NewReturnSalesInitial")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Table, { isActionAdd: _ctx.actionAdd }, null, 8, ["isActionAdd"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ReturnSaleInformation, { isActionAdd: _ctx.actionAdd }, null, 8, ["isActionAdd"])
      ])
    ]),
    _createVNode(_component_NewReturnSalesInitial, {
      onOpenModal: _ctx.handlerOpenModal,
      isActionAdd: _ctx.actionAdd
    }, null, 8, ["onOpenModal", "isActionAdd"]),
    _createElementVNode("button", _hoisted_5, null, 512)
  ]))
}